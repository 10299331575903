* {
    font-family: 'Manrope', sans-serif !important;
    margin: 0;
    padding: 0;
  }

  body, html {
    overflow-x: hidden;
  }
  
.MuiBox-root {
  padding: 0px !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background-color: rgb(132, 132, 132);

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(90, 90, 90);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 114, 114);
  border-radius: 10px;
}

a {
  text-decoration: none !important;
}

.my_profile-bg {
  width: 340px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.my_profile_user-img {
  border-radius: 100%;
  margin-left: -64px;
}

@media screen and (max-width: 900px) {
  .my_profile-bg {
      width: 100%;
      border-radius: 15px;
  }

  .my_profile_user-img {
      margin-left: 0px;
      margin-top: -64px;
  }

  .property_details-img {
      width: 100%;
      height: auto;
  }
}

.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  background-color: black;
}

.column img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transition: 0.2s ease-in-out;
  /* border-radius: 35px; */
}
.column img:hover {
  opacity: 1;
}


.nav {
  padding-left: 2rem;
  padding-right: 2rem;
}

.media {
  color: #000000;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}
a:hover {
  color: #9b9b9b;
}

/* loading */

.loading-text {
  animation: dots 0.1s infinite; /* apply the animation to the <p> element */
}

@keyframes dots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}
/* slider */

.slider-container {
  width: 40%;
  height: 60vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: rgb(0, 0, 0);
  border-radius: 30px;
  width: 5vmin;
  height: 5vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 4vmin;
  z-index: 2;
  color: #fff;
}

.next {
  right: 0px;
}

.prev {
  left: 0px;
  transform: scale(-1);
}

.slider-img {
  position: absolute;
  max-width: 60%;
  height: auto;
  border-radius: 35px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .slider-img {
    position: absolute;
    max-width: 120%;
    height: auto;
    border-radius: 35px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 768px) {
  .slider-img {
    position: absolute;
    max-width: 140%;
    height: auto;
    border-radius: 35px;
    cursor: pointer;
  }
}
